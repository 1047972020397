import React from 'react';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { Auth0Provider } from '@auth0/auth0-react';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import './index.css';
import LoginView from 'views/LoginView';
import AuthCallbackView from 'views/AuthCallbackView';
import AdminView from 'views/AdminView';
import UserManagementView from 'views/UserManagementView';
import UserManagementUsersTab from 'views/UserManagementUsersTab';
import UserManagementGroupsTab from 'views/UserManagementGroupsTab';
import UserManagementRolesTab from 'views/UserManagementRolesTab';
import ErrorView from 'views/ErrorView';
import NotFoundView from 'views/NotFoundView';
import PublicRoute from 'features/authentication/components/PublicRoute';
import ProtectedRoute from 'features/authentication/components/ProtectedRoute';
import SnowflakeAccountsView from 'views/SnowflakeAccountsView';

axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorView />,
    children: [
      {
        path: '/',
        element: (
          <ProtectedRoute>
            <AdminView />
          </ProtectedRoute>
        ),
        children: [
          {
            path: '/',
            element: <Navigate replace to="/user-management" />,
          },
          {
            path: '/snowflake-accounts',
            element: <SnowflakeAccountsView />,
          },
          {
            path: '/user-management',
            element: <UserManagementView />,
            children: [
              {
                path: '/user-management',
                element: <UserManagementUsersTab />,
              },
              {
                path: '/user-management/groups',
                element: <UserManagementGroupsTab />,
              },
              {
                path: '/user-management/roles',
                element: <UserManagementRolesTab />,
              },
            ],
          },
        ],
      },
      {
        path: '/login',
        element: (
          <PublicRoute>
            <LoginView />
          </PublicRoute>
        ),
      },
      {
        path: '/react-callback',
        element: <AuthCallbackView />,
      },
      {
        path: '*',
        element: <NotFoundView />,
      },
    ],
  },
]);

const container = document.getElementById('root');

if (container != null) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_ADMIN_UI_CLIENT_ID}
        cacheLocation="localstorage"
        authorizationParams={{
          redirect_uri: `${window.location.origin}/react-callback`,
          audience: 'https://admin.dataops.live/customer_admin/',
        }}
      >
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </Auth0Provider>
    </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
