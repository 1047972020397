import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../../app/store';
import { ISnowflakeAccount } from 'views/SnowflakeAccountsView';
import { IRequestStatus } from 'utils/types';

export interface SnowflakeAccountsState {
  accounts: ISnowflakeAccount[];
  accountsStatus: IRequestStatus;
  showError: boolean;
  errorMessage: string;
}

const initialState: SnowflakeAccountsState = {
  accounts: [],
  accountsStatus: 'idle',
  showError: false,
  errorMessage: '',
};

export const fetchAccounts = createAsyncThunk('accounts', async () => {
  const response = await axios.get('/api/v1/snowflake/account');
  return response.data;
});

export const snowflakeAccountsSlice = createSlice({
  name: 'snowflakeAccounts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccounts.pending, (state) => {
        state.accountsStatus = 'loading';
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        state.accountsStatus = 'idle';
        const processed = Object.keys(action.payload).map((key) => action.payload[key]);
        state.accounts = processed;
      })
      .addCase(fetchAccounts.rejected, (state) => {
        state.accountsStatus = 'failed';
      });
  },
});

// export const {} = snowflakeAccountsSlice.actions;

export const selectAccounts = (state: RootState): ISnowflakeAccount[] => state.snowflakeAccounts.accounts;

export default snowflakeAccountsSlice.reducer;
